import React, { useEffect, useRef, useState } from "react"
import { get } from "lodash"
import { Div } from "atomize"
import { Link } from "gatsby"
import { getProductBubbleImage } from "../../utilities/helpers"

export default function Bubble({ product, id }) {
  return (
    <Link to={`/products/${get(product, "slug.current")}`}>
      <Div
        key={id}
        minW="9.875rem"
        h="auto"
        pos="relative"
        p={{ b: "100%" }}
        rounded="circle"
        cursor="pointer"
        className="bubble"
        overflow="visible"
      >
        <Div
          pos="absolute"
          top="0"
          left="0"
          right="0"
          rounded="circle"
          bg="#c1dfff"
          shadow="5"
          bottom="0"
          className="bubble-bg animate"
        />
        <Div
          pos="absolute"
          bgImg={getProductBubbleImage(product)}
          bgSize="cover"
          bgPos="center"
          top="0"
          left="0"
          right="0"
          bottom="0"
          rounded="circle"
          className="product-image"
        />
        {/* <Div
          pos="absolute"
          bgImg="/bubble.svg"
          bgSize="cover"
          bgPos="center"
          top="0"
          left="0"
          right="0"
          bottom="0"
          rounded="circle"
          className="bubble-gradient animate"
        /> */}
      </Div>
    </Link>
  )
}
