import React from "react"
import { Div } from "atomize"

import ModuleHomeHero from "../modules/ModuleHomeHero"
import ModuleHelpdesk from "../modules/ModuleHelpdesk"
import ModuleLegal from "../modules/ModuleLegalContainer"
import ModuleLogoAnimation from "../modules/ModuleLogoAnimation"
import ModuleContentWithBubbles from "../modules/ModuleContentWithBubbles"
import ModuleContentWithFeatures from "../modules/ModuleContentWithFeatures"
import ModuleContentWithCtaAsset from "../modules/ModuleContentWithCtaAsset"
import ModuleContentWithProductCard from "../modules/ModuleContentWithProductCard"
import ModuleContentWithImage from "../modules/ModuleContentWithImage"
import PortableText from "../components/common/PortableText"
import ModulePostcard from "../modules/ModulePostcard"

/**
 * renders all page modules
 *
 * @param {*} param0
 */
export const getPageModules = ({
  page,
  location,
  legalRoutes,
  helpdeskRoutes,
  checkoutData,
  checkoutCreate,
}) => {
  const modules = (page._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null

      switch (c._type) {
        case "homeHero":
          el = <ModuleHomeHero key={c._key} {...c} />
          break
        case "features":
          el = <ModuleContentWithFeatures key={c._key} {...c} />
          break
        case "logoAnimation":
          el = <ModuleLogoAnimation key={c._key} {...c} />
          break
        case "contentWithCTAAsset":
          el = <ModuleContentWithCtaAsset key={c._key} {...c} />
          break
        case "bubbles":
          el = <ModuleContentWithBubbles key={c._key} {...c} />
          break
        case "contentWithProductCards":
          el = (
            <ModuleContentWithProductCard
              key={c._key}
              {...c}
              isHomepage={page.title === "Home"}
              isPcp={page.title === "Products"}
              checkoutData={checkoutData}
              checkoutCreate={checkoutCreate}
              index={i}
            />
          )
          break
        case "legal":
          el = (
            <ModuleLegal
              key={c._key}
              {...c}
              links={legalRoutes}
              location={location}
            />
          )
          break
        case "helpdesk":
          el = (
            <ModuleHelpdesk
              key={c._key}
              {...c}
              links={helpdeskRoutes}
              location={location}
            />
          )
          break
        case "richTextEditor":
          el = (
            <Div
              className="bgblocks"
              data-bg="#F0F0FF"
              m={{
                t: { xs: "6.125rem", md: "16.75rem" },
                b: "9.375rem",
              }}
            >
              <Div className="mission-content">
                <PortableText key={c._key} blocks={c.content} />
              </Div>
            </Div>
          )
          break
        case "contentWithImage":
          el = <ModuleContentWithImage key={c._key} {...c} />
          break
        case "postcard":
          el = <ModulePostcard key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return modules
}
