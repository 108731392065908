import {get} from "lodash"
import {graphql} from "gatsby"
import React, {useContext} from "react"
import ls from "local-storage"
import {useMutation, useQuery} from "@apollo/client"

import {SEO} from "../components/common/SEO"
// import useEmergence from "../hooks/useEmergence"
import Layout from "../components/common/Layout"
import {getPageModules} from "../services/pageService"
import GraphQLErrorList from "../components/common/GraphqQLErrorList"
import {saveCheckoutInLocalStorage} from "../services/cartService"
import {ADD_ATTRIBUTES, APPLY_DISCOUNT, CHECKOUT_BY_ID, CHECKOUT_CREATE, TOGGLE_CART,} from "../graphql/queries"
import {AppContext} from "../context";
import {isBefore} from "date-fns";

const Page = props => {
  console.log('Page props', props)

  const [state, dispatch] = useContext(AppContext);
  const [addAttributes] = useMutation(ADD_ATTRIBUTES)
  const [applyDiscount] = useMutation(APPLY_DISCOUNT, {
    optimisticResponse: response => console.log(response),
    onError: (error => console.error(error))
  })

  //***************This checkoutData has to be in parent of all the instance of moduleContentWithPropductCards */
  const [toggleCart] = useMutation(TOGGLE_CART, {
    onCompleted: () => {
    },
  })

  const {data: checkoutData, refetch: refechCheckoutData} = useQuery(
    CHECKOUT_BY_ID,
    {
      // skip: !ls("lastCheckoutID"),
      variables: {id: ls("lastCheckoutID")},
      onCompleted: result => {
        const {tracking} = state;
        const {id = null, orderStatusUrl= null} = result && result?.node || {};

        if (tracking && !tracking.expired && tracking.expiresAt && isBefore(new Date(), new Date(tracking.expiresAt)) && !tracking.email && tracking.customerId && tracking.referCode && tracking.discountCode && !orderStatusUrl) {
          if(id){
            const input = {
              customAttributes: [{key: 'referCode', value: tracking.referCode}, {
                key: 'customerId',
                value: tracking.customerId
              }]
            };
            const checkoutId = id.toString();
            addAttributes({
              variables: {
                checkoutId,
                input
              }
            }).then(result => {
              const discountCode = tracking.discountCode;
              applyDiscount({
                variables: {
                  checkoutId,
                  discountCode
                }
              }).then(result => console.info(result))

            }).catch(e => console.error(e))
          }
        } else if (orderStatusUrl) {
          ls.remove("lastCheckoutID")
        }
      }

    }
  )

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE, {
    onCompleted: result => {
      console.log('checkoutCreate in Page', result);
      //window.history.pushState("header", "Not Pot", "/#cart")
      toggleCart({variables: {cartDrawerVisible: true}})
      saveCheckoutInLocalStorage(result?.checkoutCreate?.checkout)
      refechCheckoutData({
        // skip: !ls("lastCheckoutID"),
        variables: {id: ls("lastCheckoutID")},
      })
    },
  })
  //********************************** */

  // useEmergence()
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors}/>
      </Layout>
    )
  }

  const site = (data || {}).site
  const location = props.location

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const page = data.page || data.route.page
  const legalRoutes = get(data, "legalRoutes.edges", [])
  const helpdeskRoutes = get(data, "helpdeskRoutes.edges", [])
  const modules = getPageModules({
    legalRoutes,
    helpdeskRoutes,
    page,
    location,
    checkoutData,
    checkoutCreate,
  })
  const {seo = {}, og = {}} = page;
  const pageTitle = data.route && !data.route.useSiteTitle && page.title
  const seoTitle = seo?.seo_title || pageTitle;
  const seoDescription = seo?.meta_description || '';
  const seoKeywords = seo?.focus_synonyms || [];
  const seoFocus = seo?.focus_keyword || "";
  const metaKeywords = [seoFocus].concat(seoKeywords);
  const socialTitle = og?.title || seoTitle;
  const socialDesc = og?.description || seoDescription;

  return (
    <Layout site={site}>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={metaKeywords}
        site={site}
        url={`https://notpot.com/${props.path}`}
        socialTitle={socialTitle}
        socialDesc={socialDesc}
        headComponents={site.headComponents}
      />
      {modules}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }

    legalRoutes: allSanityRoute(filter: { type: { eq: "legal" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }

    helpdeskRoutes: allSanityRoute(filter: { type: { eq: "helpdesk" } }) {
      edges {
        node {
          slug {
            current
          }
          page {
            title
          }
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`
