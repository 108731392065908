import React from "react"
import { range } from "lodash"
import { Div, Row, Col } from "atomize"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

export default function ModuleLogoAnimation({ total }) {
  return (
    <Div
      className="bgblocks"
      // data-emergence="hidden"
      data-bg="#EAF1F9"
      p={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
    >
      <WebsiteContainer>
        <Row>
          <Col size={{ xs: "12", lg: "10" }} offset={{ xs: "0", lg: "1" }}>
            {/* Mobile */}
            <Div className="logo-grid" d={{ xs: "grid", lg: "none" }}>
              {range(total)
                .slice(0, 9)
                .map(id => {
                  return (
                    <Div
                      className="animate-logo"
                      key={id}
                      w="100%"
                      bgImg="/transparent-logo.svg"
                      p={{ b: "117%" }}
                      bgSize="cover"
                      bgPos="center"
                    />
                  )
                })}
            </Div>
            {/* Desktop */}
            <Div className="logo-grid" d={{ xs: "none", lg: "grid" }}>
              {range(total).map(id => {
                return (
                  <Div
                    className="animate-logo"
                    key={id}
                    w="100%"
                    bgImg="/transparent-logo.svg"
                    p={{ b: "117%" }}
                    bgSize="cover"
                    bgPos="center"
                  />
                )
              })}
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}
