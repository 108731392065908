import React from "react"
import { Div, Row, Col, Text } from "atomize"

import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"
import LegalSidebar from "../components/legal/LegalSidebar"

const ModuleLegalContainer = ({ body, links, location, title, icon }) => {
  return (
    <>
      <WebsiteContainer>
        <Div m={{ t: { xs: "1.25rem", md: "2.5rem" } }}>
          <Row>
            <Col size={{ xs: "12", lg: "4" }}>
              <Div m={{ r: { lg: "2.75rem" }, b: { xs: "2.5rem", lg: "0" } }}>
                <LegalSidebar
                  links={links}
                  location={location}
                  title={title}
                  icon={icon}
                />
              </Div>
            </Col>
            <Col size={{ xs: "12", lg: "8" }}>
              <Text textSize="display1" fontFamily="secondary">
                {title}
              </Text>
              <Div className="legal">
                <PortableText blocks={body} />
              </Div>
            </Col>
          </Row>
        </Div>
      </WebsiteContainer>
    </>
  )
}

export default ModuleLegalContainer
