import React from "react"
import { Div, Text, Row, Col } from "atomize"
import CTALink from "../components/common/CTALink"
import VideoCard from "../components/common/VideoCard"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

export default function ModuleContentWithCtaAsset({ title, body, cta, video }) {
  return (
    <Div
      className="bgblocks"
      // data-emergence="hidden"
      data-bg="#FFFCF0"
      p={{ t: { xs: "6.25rem", lg: "9.375rem" } }}
    >
      <WebsiteContainer>
        <Row>
          <Col size={{ xs: "12", lg: "8" }} offset={{ xs: "0", lg: "2" }}>
            <Div textAlign="center" d="flex" flexDir="column">
              <Text
                textSize="display1"
                textTransform="uppercase"
                fontFamily="secondary"
              >
                {title}
              </Text>
              <Div
                textSize={{ xs: "header", lg: "title1" }}
                m={{ t: "1.25rem" }}
                className="description"
              >
                <PortableText blocks={body} />
              </Div>
              <Div m={{ t: "3.75rem", x: "auto" }}>
                <CTALink {...cta} p="0 3rem" />
              </Div>
              <Div
                m={{ t: { xs: "2.5rem", lg: "5rem" } }}
                p={{ x: { xs: "0", lg: "2.5rem" } }}
              >
                <Div
                  shadow="1"
                  rounded="lg"
                  border="1px solid"
                  borderColor="yellowPink"
                >
                  <VideoCard {...video} mobRatio="1:1" />
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}
