import React, { useEffect } from "react"
import { Link, navigate } from "gatsby"
import { get } from "lodash"
import { useQuery, useMutation } from "@apollo/client"
import { Div, Text, Anchor, Image } from "atomize"
import ls from "local-storage"

import { FETCH_CUSTOMER, TOGGLE_LOGIN_DRAWER } from "../../graphql/queries"
import CardHeader from "../common/CardHeader"

const HelpdeskSidebar = ({ links, location, title, subtitle, icon }) => {
  const { data: fetchCustomerData } = useQuery(FETCH_CUSTOMER, {
    skip: !ls("accessToken"),
    variables: { accessToken: ls("accessToken") },
  })

  const [toggleLoginDrawer] = useMutation(TOGGLE_LOGIN_DRAWER)

  useEffect(() => {
    document.documentElement.classList.add("helpdesk-page")

    return () => {
      document.documentElement.classList.remove("helpdesk-page")
    }
  }, [])

  const customer = get(fetchCustomerData, "customer")

  return (
    <>
      <Div rounded="lg" border="1px solid" borderColor="yellowPink" shadow="1">
        <CardHeader heading="faq" image={icon?.asset?.url} />
        <Div
          bg="yellow"
          p="1.875rem"
          rounded={{ b: "lg" }}
          d="flex"
          flexDir="column"
        >
          <Div m={{ b: "1.25rem" }}>
            <Text
              fontFamily="secondary"
              textSize="title1"
              style={{ lineHeight: "100%" }}
            >
              {/* {subtitle} */}
              Speak to the dealer
            </Text>
            <Div
              d="flex"
              justify="space-between"
              p={{ y: "1.875rem" }}
              align="center"
              border={{ b: "1px solid" }}
              borderColor="borderColor"
            >
              <Anchor
                p={{ x: "1rem" }}
                onClick={() => {
                  window !== undefined && window.GorgiasChat.open()
                }}
                textWeight="normal"
                textColor="blue"
                className="helpdesk-sidebar-item"
              >
                <Div textAlign="center">
                  <Image src="/chat.png" h="1.875rem" w="1.875rem" />
                  <Text
                    textWeight="bold"
                    textSize="header"
                    style={{ lineHeight: "100%" }}
                  >
                    Chat
                  </Text>
                </Div>
              </Anchor>
              <Anchor
                p={{ x: "1rem" }}
                href="mailto:hello@notpot.com"
                textWeight="normal"
                textColor="blue"
                className="helpdesk-sidebar-item"
              >
                <Div textAlign="center">
                  <Image src="/email.png" h="1.875rem" w="1.875rem" />
                  <Text
                    textWeight="bold"
                    textSize="header"
                    style={{ lineHeight: "100%" }}
                  >
                    Email
                  </Text>
                </Div>
              </Anchor>
              <Anchor
                p={{ x: "1rem" }}
                textWeight="normal"
                textColor="blue"
                className="helpdesk-sidebar-item"
                onClick={e => {
                  e.preventDefault()

                  if (customer) {
                    navigate(`/profile?tab=orders`)
                  } else {
                    toggleLoginDrawer({
                      variables: { loginDrawerVisible: true },
                    })
                  }
                }}
                href="/profile?tab=orders"
              >
                <Div textAlign="center">
                  <Image src="/track.png" h="1.875rem" w="1.875rem" />
                  <Text
                    textWeight="bold"
                    textSize="header"
                    style={{ lineHeight: "100%" }}
                  >
                    Track
                  </Text>
                </Div>
              </Anchor>
            </Div>
          </Div>
          {links.map((link, id) => (
            <Link
              to={`/${link.node.slug.current}`}
              style={{ color: "inherit" }}
            >
              <Text
                className="helpdesk-sidebar-item"
                m={{ t: "0.75rem" }}
                key={`legal-sidebar-${link.node.slug.current}`}
                textWeight={
                  location.pathname === `/${link.node.slug.current}`
                    ? "bold"
                    : "normal"
                }
                cursor="pointer"
              >
                {link.node.page.title}
              </Text>
            </Link>
          ))}
        </Div>
      </Div>
    </>
  )
}

export default HelpdeskSidebar
