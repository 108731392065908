import React from "react"
import { Div, Row, Col } from "atomize"
import VideoCard from "../components/common/VideoCard"
import CTALink from "../components/common/CTALink"
import PortableText from "../components/common/PortableText"
import WebsiteContainer from "../components/common/atoms/WebsiteContainer"

export default function ModuleHomeHero({ title, body, cta, video, is404 }) {
  return (
    <Div
      p={{
        t: { xs: "1.875rem", lg: "2.5rem" },
        b: { xs: "0", lg: "2.25rem" },
      }}
      className="bgblocks"
      data-bg="#FDEEFF"
    >
      <WebsiteContainer>
        <Row>
          <Col
            size={{ xs: "12", lg: "5", xl: "4" }}
            p={{ xs: "0 8px", lg: "0 0 0 8px", xl: "0" }}
          >
            <Div h="100%" align="center" d="flex" className="modulehomeHero">
              <Div
                d="flex"
                flexDir="column"
                align={{ xs: "center", lg: "flex-start" }}
                textAlign={{ xs: "center", lg: "left" }}
              >
                <Div className="hero-title">
                  <PortableText blocks={title} />
                </Div>
                <Div className="hero-description">
                  <PortableText blocks={body} />
                </Div>
                <Div
                  m={{
                    t: { xs: is404 ? "40px" : "4rem", lg: "4rem" },
                    b: is404 ? "100px" : "0px",
                  }}
                  order="1"
                  d={{ xs: is404 ? "block" : "none", lg: "block" }}
                >
                  <CTALink minW="9.625rem" p="0 3rem" {...cta} />
                </Div>
              </Div>
            </Div>
          </Col>
          <Col
            size={{ xs: "12", lg: "6", xl: "7" }}
            offset={{ xs: "0", lg: "1" }}
          >
            <Div
              shadow="1"
              m={{ t: { xs: "2.5rem", lg: "0" } }}
              border="1px solid"
              borderColor="yellowPink"
              rounded="lg"
            >
              <VideoCard {...video} mobRatio={is404 ? "1:1" : undefined} />
            </Div>
            <Div
              m={{ t: "3rem" }}
              d={{ xs: is404 ? "none" : "flex", lg: "none" }}
              justify="center"
            >
              <CTALink {...cta} minW="9.625rem" p="0 3rem" />
            </Div>
          </Col>
        </Row>
      </WebsiteContainer>
    </Div>
  )
}
